import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
//import Adsense from "../components/Adsense"
// import Affiliate from "../components/Affiliate"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>

          {/* <Adsense className="adsbygoogle"
            style={{ marginTop: 20, marginBottom: 20 }}
            data-ad-client="ca-pub-6089709030572614"
            data-ad-slot="7416669759"
            data-ad-format="auto"
            data-full-width-responsive="true" /> */}

          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        {/* {[
          "Location tracking in Expo and React Native",
          "Edit Android Manifest in Expo managed",
          "Turn your website into mobile application with react native",
          "How to run background tasks in Expo React Native",
        ].includes(post.frontmatter.title) && (
          <Affiliate
            link="https://www.amazon.com/gp/product/1839211148/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1839211148&linkCode=as2&tag=chafikgharbi-20&linkId=e1f0bd954f83d1a8b6d92b14abcaaa6d"
            thumb="react-react-native.png"
            title="React and React Native: A complete hands-on guide to modern web and mobile development with React.js"
            description="You want to boost your skills in developing cross-platform native apps? I recommend this book..."
          />
        )} */}
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
